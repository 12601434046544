/**
 * ITEM-PREVIEW
 *
 * Component used to preview any kind of element with image, title, text, metadata and icons.
 */

/* ------------------------------------ *\
   #ITEM-PREVIEW
\* ------------------------------------ */

/*Grid 1 last element without images*/

.n7-grid-1 *:last-child .n7-item-preview:not(.mr-item-preview-bibliography, .is-vertical.has-image) {
    border-bottom: 0;
}

