/**
* WP CONTENT
*
* Style for content coming from the WP text editor.
 */

/* ------------------------------------ *\
   IMAGE
\* ------------------------------------ */
.mr-wp-content {
    .wp-block-image {
        img {
            width: auto;
        }
    }

    ul {
        width: 100%;
        max-width: 800px;
        margin: 0 0 24px 0;
    }
}