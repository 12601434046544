/**
 * FOOTER
 *
 * Brief description of the component here.
 */

/* ------------------------------------ *\
   #FOOTER
\* ------------------------------------ */

.n7-footer {
    // background-color: #464b53;
    background-color: $color-main;

    &__column-nav-list {
        list-style: unset;
        color: $color-gray-00;
    }

    &__column-title {
        font-size: $font-size-24px-fluid;
        font-family: $font-family-main;
    }

    &__column-nav-item {
        margin-left: 16px;
    }

    &__column-images img {
        height: 45px;
    }

    &__content .n7-footer__column:first-child .n7-footer__column-images {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 3;
        gap: 15px 10px;
        justify-items: center;

        n7-anchor-wrapper *{
            margin: 0 !important;
        }

        n7-anchor-wrapper:nth-child(1) {
            justify-self: left;
            grid-column: 1 / 4;
        }

        n7-anchor-wrapper:nth-child(2) {
            grid-column: 1 / 2;
        }

        n7-anchor-wrapper:nth-child(3) {
            grid-column: 2 / 3;
        }

        n7-anchor-wrapper:nth-child(4) {
            grid-column: 1 / 2;
        }

        n7-anchor-wrapper:nth-child(5) {
            grid-column: 2 / 3;
        }

        n7-anchor-wrapper:nth-child(6) {
            grid-column: 3 / 4;
        }
    }
}

