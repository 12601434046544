//
// COLOR SCHEME
//
// Here we define all colors used in components and project
//

// ------------------------------------ //
// #COLOR-SCHEME
// ------------------------------------ //
// Grayscale (background, borders, text)
// $color-gray-00: #ffffff;
// $color-gray-01: #f9fafa; // f7f7f7;
// $color-gray-02: #eff0f1; // f0f0f0;
// $color-gray-03: #e2e3e4; // dddddd;
// $color-gray-04: #91959c; // B6B7B9; // ababab;
// $color-gray-05: #4f5259; // 494a4d;

// Brand colors
$color-main: #145E85;
$color-text: #121416;
$color-second: rgb(180 130 69);
$color-text-link-hover: #486964;
$color-text-link-hover-second: rgb(146 99 41);

// Status colors
// $color-error: #d12c47;
// $color-warning: #f5a623;
// $color-success: #3f7600;

// ------------------------------------ //
// #HIGHLIGHT-COLOR
// ------------------------------------ //
// $color-highlight: rgba(255, 251, 0, 0.18);

// ------------------------------------ //
// #ENTITIES-COLORS
// ------------------------------------ //
// $color-people: #4d8ff2; // Fallback
// $color-people: var(--color-persona);

// $color-places: #ebc738; // Fallback
// $color-places: var(--color-luogo);

// $color-concepts: #33bdcc; // Fallback
// $color-concepts: var(--color-concetto);

// $color-things: #5ba878; // Fallback
// $color-things: var(--color-cosa-notevole);

// $color-organizations: #bd883e; // Fallback
// $color-organizations: var(--color-organizzazione);

// $color-oggetto-culturale: #9a84c2; // Fallback
// $color-oggetto-culturale: var(--color-oggetto-culturale);

// $color-aggregazione-logica: #a6a5a8; // Fallback
// $color-aggregazione-logica: var(--color-aggregazione-logica);

// $color-families: #cc6f5c; // Fallback
// $color-families: var(--color-famiglia);

// $color-events: #e89827; // Fallback
// $color-events: var(--color-evento);

/* $color-people: #3a81f2; // Fallback
$color-people: var(--color-persona);

$color-places: #f2cd3a; // Fallback
$color-places: var(--color-luogo);

$color-concepts: #5eab7b; // Fallback
$color-concepts: var(--color-concetto);

$color-organizations: #c48731; // Fallback
$color-organizations: var(--color-organizzazione);

$color-families: #b14254; // Fallback
$color-families: var(--color-famiglia);

$color-events: #dd8d1c; // Fallback
$color-events: var(--color-evento);

$color-things: #c43631; // Fallback
$color-things: var(--color-cosa-notevole);

$color-oggetto-culturale: #a798c2; // Fallback
$color-oggetto-culturale: var(--color-oggetto-culturale);

$color-aggregazione: #cc6f5c; // Fallback
$color-aggregazione: var(--color-aggregazione); */
