/**
 * HEADER
 *
 * Brief description of the component here.
 */

/* ------------------------------------ *\
   #HEADER
\* ------------------------------------ */

.n7-header {
    height: 80px;
    background-color: $color-main;
}

.n7-header__title-subtitle {
    height: 45px;
}

.n7-header__nav-item {
    padding: 0 15px;
}

.n7-header__nav-label,
.n7-header__subnav-link {
    font-family: $font-family-headings;
    font-size: $font-size-14px-fluid;
    letter-spacing: 2.5px;
    font-weight: 500;
    text-transform: uppercase;
    transition: color 0.2s;
}

.n7-header__nav-link,
.n7-header__subnav-link {
    color: white;
}

.n7-header__subnav-link {
    font-size: $font-size-16px-fluid;
}