/**
 * SIMPLE SEARCH
 *
 * Specific style for the Single Search page.
 */

/* ------------------------------------ *\
   #SIMPLE SEARCH
\* ------------------------------------ */

.mr-search__results-content {
  // FILTERS
  .mr-facets__contents {
    .mr-facets__title,
    .n7-facet-header__text {
      font-family: $font-family-main;
    }

    // Input
    .n7-input-text__text {
      border-radius: 5px;
      border: none;
      box-shadow: 0 4px 4px #00000014, 0 13px 30px #0000000d;
    }

    // Num of results
    .n7-input-link__counter {
      background-color: $color-main;
      border-radius: 20px;
      padding: 3px 15px;
    }
  }

  // RESULTS
  .mr-search__results-wrapper {
    // Results order
    .n7-inner-title__sorting-select {
      border-radius: 5px;
      border: none;
      box-shadow: 0 4px 4px #00000014, 0 13px 30px #0000000d;
    }

    // Results card
    .n7-item-preview {
      border-radius: 5px;
      border: none;
      box-shadow: 0 4px 4px #00000014, 0 13px 30px #0000000d;
      padding: 20px;

      h1 {
        font-family: $font-family-headings;
        font-size: $font-size-24px-fluid;
        margin-bottom: 10px;
      }
    }

    .n7-item-preview:hover {
      h1 {
        color: $color-text-link-hover-second;
      }
    }

    // Pagination
    .n7-pagination li {
      border-radius: 5px;
      border: none;
      box-shadow: 0 4px 4px #00000014, 0 13px 30px #0000000d;
    }
  }
}
