/* ------------------------------------ *\
   #METADATA-DYNAMIC-ACCORDION
\* ------------------------------------ */


// CONTENITORE RISORSA --> Attenzione applica i cambiamenti su tutto il sito !!!
.mr-resource__content,
.mr-resource__title {
    max-width: 1160px !important;
}

.mr-resource__top .mr-resource__metadata .mr-content-block-metadata {
    max-width: 1160px !important;
    margin: 0 auto;
}

.mr-resource__metadata-dynamic {
    margin: 0 35px !important;
}

.mr-resource__content {
    border-radius: 10px !important;
}


// TABS
.mr-resource__tabs {
    max-width: 1136px !important;
    margin-bottom: 20px !important;
    margin-top: 40px !important;
    border-radius: 10px;
    padding: 12px;
    background-color: #f0f0f0;

    .mr-resource__tabs-item {
        font-family: $font-family-main !important;
        border-radius: 6px !important;
    }
}


.mr-metadata-dynamic {
    // Label principali metadati
    .n7-metadata-viewer__wrapper > div > .n7-metadata-viewer__group-wrapper > .n7-metadata-viewer__group .n7-metadata-viewer__item-label,
    .n7-metadata-viewer__group-title {
        text-align: right;
        margin-right: 60px !important;
        margin-left: 20px;
        width: 180px !important;
        font-size: 14px !important;
    }

    // Label secondarie metadati
    .n7-metadata-viewer__wrapper > div > .n7-metadata-viewer__group-wrapper > div > .n7-metadata-viewer__group-wrapper .n7-metadata-viewer__item {
        flex-direction: column;

        .n7-metadata-viewer__item-label {
            width: 80%;
        }
    }

    // Link (nomi di persona)
    a {
        color: rgb(180 130 69);
    }

    // Testo
    .n7-metadata-viewer {
        &__group-content {
            border-bottom: none !important;
        }

        &__item-value {
            font-size: 14px;
            max-height: none;
        }
    }

    // Linea divisoria
    .mrc-uc_end {
        width: 94%;
        border-bottom: 1px solid rgb(0 0 0 / 15%);
        margin-left: 3%;
        margin-bottom: 32px;
    }
}


// ACCORDION
.mr-metadata-dynamic-accordion {
    // Header
    &__header {
        margin-bottom: 0;

        // padding-top: 15px;
        // padding-bottom: 15px;
        border: none;
        border-bottom: 2px solid #f0f0f0;
        border-radius: 0;
        box-shadow: none;
    
        .n7-facet-header {
            height: auto;
            align-items: end;
            margin-top: 10px;
            margin-bottom: 10px;
    
            &__text {
                font-family: $font-family-headings;
                line-height: 25px;
            }

            &__text-wrapper {
                padding-left: 28px;
            }
    
            &__icon-right {
                color: rgb(0 0 0 / 51.4%);
            }
        }

        .n7-facet-header:hover .n7-facet-header__icon-right {
            color: rgb(0 0 0 / 51.4%);
        }
    }

    // Content box
    .mr-content-block__content {
        padding-top: 45px;
        padding-bottom: 45px;
        border: 1px solid rgb(0 0 0 / 15%);
        border-top: 3px solid rgb(195 157 110);
        border-radius: 0 0 10px 10px;
    }
}

// Padding a label a "Altri nomi legati al carteggio"
.mrc-mittente + div .n7-metadata-viewer__item-label + .n7-metadata-viewer__item-value,
.mrc-mittente + div .n7-metadata-viewer__item-label,
.mrc-altri_nomi_legati_al_carteggio + div .n7-metadata-viewer__item-label + .n7-metadata-viewer__item-value,
.mrc-altri_nomi_legati_al_carteggio + div .n7-metadata-viewer__item-label {
    margin-left: 50px;
}

.mrc-mittente + div *,
.mrc-altri_nomi_legati_al_carteggio + div * {
    margin-bottom: 0 !important;
}

.mrc-mittente + div .n7-metadata-viewer__item-label + .n7-metadata-viewer__item-value,
.mrc-altri_nomi_legati_al_carteggio + div .n7-metadata-viewer__item-label + .n7-metadata-viewer__item-value {
    margin-bottom: 15px !important;
}

.mrc-storia_del_manoscritto + div > .n7-metadata-viewer__group-wrapper > .n7-metadata-viewer__group {
    margin-bottom: 0 !important;
}

