/**
 * HOME
 *
 * Specific style for the Homepage.
 */

/* ------------------------------------ *\
    HERO
\* ------------------------------------ */

/* Hero 1 */
.mr-home {
    .n7-hero-main {
        height: 580px;

        .n7-hero__content {
            position: relative;
            z-index: 10;
            max-width: 1170px;
            margin: 0 auto;
        }

        .n7-hero__text-wrapper {
            height: $home-hero-1-height;
        }

        .n7-hero__title {
            font-size: $font-size-55px-fluid;
            color: $color-main;
            margin: 0;
            font-weight: 500;
        }
        
        .n7-hero__text {
            margin-bottom: 0;
            max-width: 100%;
            font-weight: $font-weight-normal;
            color: $color-text;
            line-height: 1;

            h1, h2, h3, h4, h5, h6, p, strong {
                font-weight: $font-weight-normal;
                color: $color-text;
                line-height: 1.3;
            }

            h1 {
                font-size: $font-size-73px-fluid;
                font-weight: $font-weight-bold;
            }

            h2 {
                font-size: $font-size-m + 8;
            }

            h3 {
                font-size: $font-size-m + 7;
            }

            h4 {
                font-size: $font-size-m + 6;
            }
            
            h5 {
                font-size: $font-size-m + 4;
            }

            h6 {
                font-size: $font-size-m + 2;
            }

            p {
                font-size: $font-size-24px-fluid;
            }
        }

        .n7-hero__input-wrapper {
            width: 100%;
            text-align: right;
        }
    }

    .n7-hero.has-background-image::after {
        position: absolute;
        content: " ";
        inset: 0;
        background-color: transparent !important;
        transition: all 0.2s;
    }


}

.mr-home.mr-layout {
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 33% 67%;
    padding: 0 16px;
    grid-template-areas: 
    "hero hero"
    "collection collection"
    "content content";

    // hero-main
    .n7-hero-main {
        grid-area: hero;
        position: absolute;
        background-position: 70%;
        background-size: contain;
        left: 0;
        width: 100%;
        background-color: #F3ECE2;

        .n7-hero__title {
            width: 50%;
            margin-bottom: 20px;
        }
    }

    // collection-hero
    .mr-layout__collection {
        grid-area: collection;
        margin-top: 300px;
        
        .n7-item-preview__content {
            position: absolute;
            z-index: 2;
            inset: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0;
            filter: drop-shadow(0 4px 20px rgb(0 0 0 / 15%));
            border-radius: 13px;
            border: 5px solid white;
            overflow: hidden;
        }

        .n7-item-preview__inner {
            height: 390px;
            transition: all 0.2s;
        }

        .n7-item-preview__inner:hover {
            transform: translateY(-10px);
            filter: drop-shadow(0 4px 8px rgb(0 0 0 / 15%));

            .n7-item-preview__title {
                color: $color-gray-00;
            }
        }

        .n7-item-preview__title {
            font-family: $font-family-headings;
            font-weight: 500;
            text-align: left;
            color: $color-gray-00;
            font-size: $font-size-32px-fluid;
            margin-bottom: 20px;
        }

        .n7-item-preview__title::after {
            content: '';
            display: inline-block;
            width: 25px;
            height: 25px;
            position: absolute;
            right: 20px;
            background: url('../../assets/arrow-home.png');
            background-size: contain;
            background-repeat: no-repeat;
        }

        
        .n7-item-preview__text {
            font-family: $font-family-headings;
            margin-bottom: 0;
            text-align: left;
            font-size: $font-size-16px-fluid;
        }

        .n7-item-preview__title-text {
            width: 100%;
            height: 180px;
            background-color: $color-main;
            padding: 30px 20px;
            box-sizing: border-box;
        }

        .n7-item-preview__image {
            border-radius: 14px;
        }

        .n7-item-preview__image::after {
            content: " ";
            position: absolute;
            z-index: 1;
            inset: 0;
            background-color: transparent;
        }

        .mr-items-preview {
            padding-top: 24px;
            padding-bottom: 0;
        }

        .n7-item-preview:not(.mr-item-preview-bibliography, .is-vertical.has-image) {
            border-bottom: unset;
            padding-bottom: 0;
            height: 100%;
            box-sizing: border-box;
        }
        
    }

    // content-main
    section:nth-child(3) {
        grid-area: content;
    }

    .n7-hero-works {
        background-color: transparent;
        width: 70%;
        margin-left: 15%;


        .n7-hero__text h2{
            color: $color-main;
            font-size: 30px;
            margin-bottom: 40px;
        }

        .n7-hero__text p{
            color: $color-text;
            font-size: 20px;
        }

        .n7-hero__text a{
            color: $color-text-link-hover-second;
            font-size: 20px;
        }
    }
}




/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (width <= 1120px) {
}

@media all and (max-width: ($page-max-width + $space*4)) {
    .mr-home {
        
    }

}

@media all and (max-width: $breakpoint-laptop) {
}

@media all and (max-width: $breakpoint-ipad-portrait) {
    .mr-home.mr-layout {
        box-sizing: border-box;
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0 16px;
        grid-template-areas: 
        "hero hero"
        "collection collection"
        "content content";
    }

    .n7-grid-2 > * {
        width: 100%;
        margin-right: 0;
    }

    .mr-home .n7-hero-main .n7-hero__text {
        max-width: 100%;
    }

    .n7-header__mobile-menu-toggle {
        height: 75px;
    }
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
    .mr-home.mr-layout section:nth-child(4) .n7-item-preview__inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mr-home.mr-layout section:nth-child(4) .n7-item-preview__image {
        margin: 16px;
    }

    .n7-footer__column-nav-list {
        margin-left: 14px;
    }


}

/* Retina */
@media (min-device-pixel-ratio: 2), (resolution >= 192dpi) {
}
