/**
 * RESOURCE
 *
 * Specific style for the Resource page.
 */

/* ------------------------------------ *\
   #RESOURCE
\* ------------------------------------ */
.mr-resource {
    .n7-inner-title__wrapper-texts div {
        font-family: $font-family-headings !important;
        margin-top: 15px;

    }

    .n7-inner-title__subtitle {
        font-size: $font-size-24px-fluid;
    }
    
    .mr-resource__collection {
        padding: $space * 3 0;
        margin: 0 $space * 10;

        .n7-item-preview__inner .n7-item-preview__title .sample-icon {
            float: right;
            color: $color-main;
            font-size: $font-size-xl;
        }
    }

    .mr-content-block__title {
        font-size: $font-size-l-xl;
    }

    .mr-content-block-metadata .n7-metadata-viewer__item-value {
        line-height: normal;
    }
    
    .mrc-list_events .n7-metadata-viewer__item-value p {
        margin-bottom: 4px;
        padding-bottom: 4px;
        border-bottom: 1px solid #f0f0f0;
    }

    .motif_related {
        display: inline-block;
        margin-left: 20px;
    }
}