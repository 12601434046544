/**
 * TEXT-VIEWER
 *
 * Muruca component that implements TEI Publisher text viewer.
 */
 
/* ------------------------------------ *\
   #WEB COMPONENTS STYLING
\* ------------------------------------ */

.n7-text-viewer {
    pb-page {
        --pb-content-font-size: 22px;
        --pb-toolbar-height: 52px;
        --pb-toolbar-border-bottom-size: 1px;
        
        main {
            height: 75vh; //75vh
            
            pb-view {
                padding: 30px;
            }

            pb-view:nth-child(2) {
                padding-top: 20px;
                font-size: 1.8vh;
                position: relative;
            }

            pb-view:nth-child(3) {
                padding-top: 20px;
                font-size: 14px;
            }

            pb-view:nth-child(4) {
                padding-top: 20px;
                font-size: 14px;
            }
        }

        pb-drawer {
            height: calc(100vh - 74px);
            width: 450px;
            top: calc(var(--pb-toolbar-height) + var(--pb-toolbar-border-bottom-size));
    
            li {
                margin-bottom: 15px;
            }
        }

        .toolbar {
            background-color: #e9e9e9;
            border-bottom: var(--pb-toolbar-border-bottom-size) solid lightgrey;
            height: var(--pb-toolbar-height);
        }
    }
    
}

@media all and (max-width: 1024px) {
    
    .n7-text-viewer pb-facsimile,
    .n7-text-viewer pb-view {
        display: none;
    }
    #view0 {
        display: block;
        padding: 0;
    }
}